import App from "./app.js";
import "./feedback.js";
require("./style/index.less");

// import * as d3 from "d3";
let d3 = require("d3");

// let link_csv_path = require("../process/2018/every_link.csv");
// let node_csv_path = require("../process/2018/every_node.csv");
// console.log("hi");
// console.log("process.env.NODE_ENV:", process.env.NODE_ENV);

let file_array = [
  {
    year: 2018,
    // links: require("../process/out/every_link_2018.csv"),
    // nodes: require("../process/out/every_node_2018.csv")
    links: require("../../pactrack_pandas_notebook/out/every_link_2018.csv"),
    nodes: require("../../pactrack_pandas_notebook/out/every_node_2018.csv")
  },
  {
    year: 2016,
    links: require("../../pactrack_pandas_notebook/out/every_link_2016.csv"),
    nodes: require("../../pactrack_pandas_notebook/out/every_node_2016.csv")
  },
  {
    year: 2014,
    links: require("../../pactrack_pandas_notebook/out/every_link_2014.csv"),
    nodes: require("../../pactrack_pandas_notebook/out/every_node_2014.csv")
  },
  {
    year: 2012,
    links: require("../../pactrack_pandas_notebook/out/every_link_2012.csv"),
    nodes: require("../../pactrack_pandas_notebook/out/every_node_2012.csv")
  },
  {
    year: 2010,
    links: require("../../pactrack_pandas_notebook/out/every_link_2010.csv"),
    nodes: require("../../pactrack_pandas_notebook/out/every_node_2010.csv")
  },
  {
    year: 2008,
    links: require("../../pactrack_pandas_notebook/out/every_link_2008.csv"),
    nodes: require("../../pactrack_pandas_notebook/out/every_node_2008.csv")
  }
];

if (process.env.NODE_ENV != "production") {
  // add simple fake dev dataset
  file_array.unshift({
    year: 2050,
    links: require("../../pactrack_pandas_notebook/out/every_link_2050.csv"),
    nodes: require("../../pactrack_pandas_notebook/out/every_node_2050.csv")
  });
}

let last_updated = require("../../pactrack_pandas_notebook/out/last_updated.json");
last_updated = new Date(last_updated[0][0]);
// file_array[0]["last_updated"] = last_updated;

d3.select("#last_updated").html(`Last Updated ${last_updated.toDateString()}`);
// console.log(last_updated);
// console.log(file_array);

let hide_info = document.cookie.includes("show_info=false");

d3.select("#ui").classed("hide_info", hide_info);
// d3.select("#ui nav").html("");

d3.select("#close_info").html(`<i class="material-icons">cancel</i>Close`);
d3.select("#close_info").on("click", e => {
  document.cookie = "show_info=false;max-age=86400"; // one day
  d3.select("#ui").classed("hide_info", true);
});

d3.select("#ui #info .more_info_link").on("click", () => {
  d3.select("#ui").classed("show_more", true);
});

let app = new App(d3.select("#graph"), d3.select("nav"), file_array);
// let app = new App(d3.select("#graph"), d3.select("nav"), { links, nodes });
window.app = app;

// // console.log("begin load csv");
// d3.csv(link_csv_path).then(function(links) {
//   links.forEach(l => {
//     l["sum"] = parseInt(l["sum"]);
//   });
//   d3.csv(node_csv_path).then(function(nodes) {
//     // console.log("done loading ");
//     // console.log("nodes:", nodes.length, "links:", links.length);
//     let app = new App(d3.select("#graph"), d3.select("nav"), { links, nodes });
//     window.app = app;
//   });
// });
