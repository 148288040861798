// we're using parcel globals to load this from a cdn in prod (and yarn startcdn)
let d3 = require("d3");

// if we're not in prod stub this
// if (process.env.NODE_ENV != "production") {
//   window.gtag = (a, b) => {
//     // console.log("fake sending to GA:");
//     // console.log(a, b);
//   };
// }

if (!window.gtag) {
  window.gtag = (a, b) => {};
}

import PathSearch from "./search";
import pacGraph from "./graph";
import {
  wrap,
  MakeGradientDef,
  make_unique_by,
  make_marker_def,
  setup_marker_defs,
  make_image_def,
  node_subset_from_links,
  make_links_unique,
  Debouncer,
  // create_official_links,
  partition
} from "./util";

import Nav from "./nav";

import colors from "./colors";
let format_big = d3.format(",.2s");
// let debouncer = new Debouncer();

export default class App {
  constructor(parent_el, nav_el, file_array) {
    this.file_array = file_array;
    this.nav_el = nav_el;
    this.parent_el = parent_el;

    this.on_change = this.on_change.bind(this);
    // default values for state
    let year = this.file_array[0]["year"];
    // let query = { origin: [], dest: [] };
    // ok now parse the url's hash path
    let path = location.hash.slice(2);

    gtag("event", `${location.hash}`, {
      event_category: `begin`
    });
    year = path.split("/")[0].length ? path.split("/")[0] : year;

    let origin_re = /(?:\/origin\/)([^\s\/]*)/;
    let dest_re = /(?:\/dest\/)([^\s\/]*)/;
    let origin_match = origin_re.exec(path);
    let dest_match = dest_re.exec(path);
    let origin = origin_match ? origin_match[1].split(",") : [];
    let dest = dest_match ? dest_match[1].split(",") : [];

    let include_official_linkages = path.includes("/show_official/");
    let is_paused = path.includes("/paused/");
    if (path.endsWith("/random/")) {
      origin = ["random"];
      dest = [];
      // console.log(path, "random");
    }

    // make this consistent for later use
    origin = origin.map(id => {
      return { id };
    });
    dest = dest.map(id => {
      return { id };
    });

    let query = { origin, dest };

    this.state = {
      query,
      year,
      is_paused,
      loading: true,
      show_advanced: false,
      include_official_linkages,
      total_link_count: 0,
      total_node_count: 0,
      displayed_link_count: false,
      displayed_node_count: false,
      limit: 100,
      attempts: 4,
      depth: 3,
      preserve_extant: false,
      possible_years: this.file_array.map(x => x.year)
    };

    // get rid of loading, hmr fix in dev, shouldn't effect prod
    this.parent_el.html(``);
    this.root_el = this.parent_el.append("svg");
    // console.log(this.root_el)
    let defs = this.root_el.append("svg:defs");
    setup_marker_defs(defs, colors.transaction_link);
    // make a group to hold everything
    this.root_el.append("rect").attr("id", "back");

    this.root_el.append("g").attr("id", "container");

    this.resize();
    d3.select(window).on("resize", this.resize.bind(this)); // throttle ?

    this.nav = new Nav(this.nav_el);
    // re add the loading div
    this.parent_el
      .append("div")
      .attr("id", "loading")
      .html(loading_div);

    // this.nav.render(this.state);
    this.initial_load();
  } // end constructor
  async initial_load() {
    let { nodes, links } = await this.load_data_files_for_year();

    // store it all here, it's too big to display
    this.all_graph_data = { nodes, links };

    // special case, random, just one random origin from the top 1000 links
    if (
      this.state.query.origin[0] &&
      this.state.query.origin[0]["id"] == "random"
    ) {
      let id = this.get_randomish_id();
      this.state.query.origin = [{ id }];
    }

    // helper function for below lookup
    let obj_ify = ({ id }) => {
      let item = nodes.find(n => n["id"] == id);
      // return item ? { name: item["name"], id } : false;
      return item ? item : false;
    };

    // if query exists, from the url, we want to populate the names of the nodes for the nav ui
    // i.e. this.state.query goes from [id,id...] to [{id, name}, {id, name}]
    // and then remove the missing ones
    this.state.query.origin = this.state.query.origin
      .map(obj_ify)
      .filter(x => x);
    this.state.query.dest = this.state.query.dest.map(obj_ify).filter(x => x);
    // console.log(this.state.query);
    this.setup_ui_with_new_year();

    let gd = await this.filter_data_by_state();
    gd = this.flag_active(gd);
    //initialize the viz
    this.pacgraph = pacGraph()
      .width(this.state.width)
      .height(this.state.height)
      // .hover(d => this.on_hover(d))
      .node_click(d => this.on_node_click(d))
      .paused(this.state.is_paused, true)
      .graph_data(gd);
    // put it in the dom
    this.root_el.select(`#container`).call(this.pacgraph);
    // maybe put this in the change pos too.
    this.state.loading = false;
    this.state["displayed_link_count"] = gd.links.length;
    this.state["displayed_node_count"] = gd.nodes.length;
    this.parent_el.select("#loading").attr("class", "done");
    // console.log("aaaass-wefoij", this.parent_el.select("#loading"));
    this.nav.render(this.state);
    this.set_url_hash_from_state();
  }
  get_randomish_id() {
    let links = this.all_graph_data.links.slice(0, 1000);
    let res = links[Math.floor(Math.random() * links.length)]["source"];
    return res;
  }
  on_node_click(d) {
    // console.log(d);
    if (d) {
      // debouncer.cancel("details");
      this.nav.details(d);
    } else {
      this.nav.details();
    }
  }
  flag_active(gd) {
    let origin_ids = this.state.query.origin.map(x => x.id);
    let dest_ids = this.state.query.dest.map(x => x.id);

    gd.nodes = gd.nodes.map(n => {
      let [org_index, dest_index] = [
        origin_ids.indexOf(n.id),
        dest_ids.indexOf(n.id)
      ];
      // is this an active one? if not, return it, making sure to set it false
      if (org_index === -1 && dest_index === -1) {
        n["active_query"] = false;
        return n;
      }
      let index = org_index == -1 ? dest_index : org_index;
      let len = org_index == -1 ? dest_ids.length : origin_ids.length;

      n["active_query"] = true;
      let offset = (-len * 200) / 2;
      n["fx"] = this.state.width / 2 + offset + index * 200;
      // n["fx"] = this.state.width / 2 + ((index % 2) * -1 + index * 200);
      let put_on_bottom = n.id[0] != "C" || dest_index != -1;
      // console.log(put_on_bottom, n.id[0]);
      n["fy"] = !put_on_bottom
        ? this.state.height * 0.15
        : this.state.height * 0.85;
      return n;
    });
    return gd;
  }
  // we use this to include the linkages, because they contain no $$$ so won't end up in the top n
  get_official_linkages(nodes) {
    let official_links = this.all_graph_data.links.filter(a => a.official);
    // console.log(official_links.length);
    let node_ids = nodes.map(x => x["id"]);
    let res = official_links.filter(a => {
      // switched to use _id! XXX
      return node_ids.includes(a.source_id) || node_ids.includes(a.target_id);
    });
    return res;
  }
  async maybe_include_official_linkages({ nodes, links }) {
    if (!this.state.include_official_linkages) {
      return { nodes, links };
    }
    let official_links = this.get_official_linkages(nodes);
    links = links.concat(official_links);
    nodes = node_subset_from_links(this.all_graph_data.nodes, links);
    return { nodes, links };
  }
  get_extant_graph_data() {
    if (!this.pacgraph) {
      return [[], []];
    } else {
      let gd = this.pacgraph.graph_data();
      return [gd["links"], gd["nodes"]];
    }
  }
  async filter_data_by_state() {
    // console.log("filter_data_by_state");
    // console.log(this.state.query);
    let { origin, dest } = this.state.query;

    // no query - default view, we're looking at a general view of top stuff,
    if (origin.length == 0 && dest.length == 0) {
      // console.log("default mode");
      let links = this.all_graph_data.links.slice(0, this.state.limit);
      let nodes = node_subset_from_links(this.all_graph_data.nodes, links);
      let gd = await this.maybe_include_official_linkages({ nodes, links });
      return gd;
    }

    if (origin.length && dest.length) {
      let [existing_links, existing_nodes] = [[], []];
      // to include the extant ones,
      if (this.state.preserve_extant) {
        [existing_links, existing_nodes] = this.get_extant_graph_data();
      }
      // console.log("ok there are both, do a path search");
      let { nodes, links } = this.ps.search_for_paths(
        origin.map(({ id }) => id),
        dest.map(({ id }) => id),
        this.state.attempts,
        existing_links,
        existing_nodes
      );

      let gd = await this.maybe_include_official_linkages({ nodes, links });
      nodes = gd["nodes"];
      links = gd["links"];

      return { nodes, links };
    } else {
      // otherwise it's just source or dest
      // formerly! or just pacs so we recurse
      let { depth, limit } = this.state;
      let dir = origin.length ? "source" : "target";
      let q = dest.length ? dest : origin;
      // console.log(q);
      q = q.map(({ id }) => id);
      let { nodes, links } = this.ps.traverseContrib(q, depth, limit, dir);
      // console.log("traverseContrib results:");
      // console.log(nodes, links);
      let gd = await this.maybe_include_official_linkages({ nodes, links });
      return gd;
    }
  }
  async load_data_files_for_year() {
    let year = this.state.year;
    // console.log(year, this.file_array);
    let year_record = this.file_array.find(x => year == x["year"]);
    // console.log("year_record");
    // console.log(year_record);
    let links = await d3.csv(year_record["links"]);
    // console.log(lins.length);
    links.forEach(a_link => {
      a_link["sum"] = parseInt(a_link["sum"]);
      // XXX do this because d3.force will replace 'source' and 'target' with the actual objects when one is loaded into the viz, which ends up being inconsistent
      a_link["source_id"] = a_link["source"];
      a_link["target_id"] = a_link["target"];
      return a_link;
    });
    let nodes = await d3.csv(year_record["nodes"]);
    this.state["total_node_count"] = nodes.length;
    this.state["total_link_count"] = links.length;
    // links = create_official_links(nodes, links);
    return { nodes, links };
  }

  async on_change(kind, change) {
    // special case, doesn't efect graph, just nav
    if (kind == "toggle_advanced") {
      this.state.show_advanced = !this.state.show_advanced;
      this.nav.render(this.state);
      return;
    }
    // normal cases below
    let { query } = this.state;
    this.state.loading = true;
    // reset this so it's effectively false by default
    // and we change it only on attempts increase (and now pause)
    this.state.preserve_extant = false;

    this.parent_el.select("#loading").attr("class", "");

    // this.parent_el.select("#loading").classed("done", false);
    this.nav.render(this.state);
    gtag("event", `change-${kind}`, {
      event_category: `nav-change`,
      event_label: get_ga_label(change)
    });

    // some changes require us to clean out the highlighted paths that we write to the data
    let needs_path_clean = false;
    // only applies when paused
    let needs_sim_run = true;
    let needs_zoom_reset = true;
    // console.log("change:", kind, change);
    // year is a special case, as we're loading the data anew
    if (kind == "year") {
      // console.log("year, this one is different");
      this.state.year = parseInt(change);
      let { nodes, links } = await this.load_data_files_for_year();
      this.all_graph_data = { nodes, links };
      // reset the query
      this.state.query = { origin: [], dest: [] };
      // if we're paused, we'll need to run the sim silently
      // needs_sim_run = true;
      this.setup_ui_with_new_year();
    } else if (kind == "limit") {
      this.state.limit = parseInt(change);
    } else if (kind == "search_remove_dest") {
      query.dest = query.dest.filter(x => x["id"] != change);
      this.state.query = query;
      needs_path_clean = true;
      needs_zoom_reset = false;
    } else if (kind == "search_remove_origin") {
      query.origin = query.origin.filter(x => x["id"] != change);
      this.state.query.origin = query.origin;
      needs_path_clean = true;
    } else if (kind == "search_add_origin") {
      query.origin.push(change);
      this.state.query = query;
      needs_path_clean = true;
    } else if (kind == "search_add_dest") {
      query.dest.push(change);
      this.state.query = query;
      needs_path_clean = true;
      // this.set_url_hash_from_state();
    } else if (kind == "include_official") {
      this.state.include_official_linkages = change;
      needs_zoom_reset = false;
    } else if (kind == "attempts") {
      // Todo make this like depth again, an input
      // and turn on preserve_extant here, and turn it off in the other cases
      // this.state.preserve_extant = true;
      this.state.attempts = parseInt(change);
      // this.state.attempts += 2;
    } else if (kind == "depth") {
      this.state.depth = parseInt(change);
    } else if (kind == "pause") {
      this.state.is_paused = change;
      needs_zoom_reset = false;
      needs_sim_run = false;
      // XX ??  kinda hacky but
      // this.state.preserve_extant = true;
    } else {
      console.log("doh", kind, change);
    }
    this.clean_path(needs_path_clean);

    let gd = await this.filter_data_by_state();
    // make'em active
    this.state["displayed_link_count"] = gd.links.length;
    this.state["displayed_node_count"] = gd.nodes.length;
    this.state.loading = false;
    this.parent_el.select("#loading").classed("done", true);
    this.nav.render(this.state);
    this.set_url_hash_from_state();
    gd = this.flag_active(gd);
    this.pacgraph.paused(this.state.is_paused, needs_sim_run);
    this.pacgraph.graph_data(gd, needs_zoom_reset);
  }
  clean_path(needs_path_clean) {
    // this is kinda dumb, but simple, resets the best_path flag set by the search
    // assumed it would be slow but p fast
    if (!needs_path_clean) {
      return;
    }
    // let t = new Date();
    // console.log(t);
    this.all_graph_data.links.forEach(d => {
      d["best_path"] = false;
    });
    // let t2 = new Date();
    // console.log(t2);
    // console.log(t2 - t);
  }
  // to be called once we have all the data for a cycle has loaded
  setup_ui_with_new_year() {
    let nav_options = {
      // possible_years,
      on_change: this.on_change
    };
    this.nav.setup(this.all_graph_data, nav_options);
    this.nav.render(this.state);
    this.ps = new PathSearch(
      this.all_graph_data.nodes,
      this.all_graph_data.links
    );
  }
  resize() {
    this.state.width = window.innerWidth;
    this.state.height = window.innerHeight;
    this.root_el
      .attr("width", this.state.width)
      .attr("height", this.state.height);

    this.root_el
      .select("#back")
      .attr("width", this.state.width)
      .attr("height", this.state.height);

    if (this.pacgraph) {
      this.pacgraph.width(this.state.width).height(this.state.height);
    }
  }
  set_url_hash_from_state() {
    // return; //xxx
    let { dest, origin } = this.state.query;
    // console.log("set_url_hash_from_state", origin, dest);
    let origin_str = origin.length
      ? "origin/" + origin.map(x => x.id).join(",") + "/"
      : "";
    let dest_str = dest.length
      ? "dest/" + dest.map(x => x.id).join(",") + "/"
      : "";
    let include_official = this.state.include_official_linkages
      ? "show_official/"
      : "";
    let is_paused = this.state.is_paused ? "paused/" : "";
    // console.log(include_official);
    let hash_string = `#/${
      this.state.year
    }/${origin_str}${dest_str}${include_official}${is_paused}`;
    history.replaceState(null, null, hash_string);
  }
}

function get_ga_label(change) {
  if (change.id) {
    return change.id;
  } else {
    return change.toString();
  }
}

let loading_div = `<span><strong>⏳</strong>Loading</span>`;
// let loading_div = `<div id="loading"><span><strong>⏳</strong>Loading</span></div>`;
