// import * as d3 from "d3";
let d3 = require("d3");
let FuzzySearch = require("fz-search");
// console.log(FuzzySearch);
const autoComplete = require("js-autocomplete");
require("js-autocomplete/auto-complete.css");

import { partition, get_img_url } from "./util";

let format_big = d3.format(",.2s");

export default class Nav {
  constructor(root_el) {
    this.root_el = root_el;
    this.ac_source = this.ac_source.bind(this);
    this.ac_source_no_cand = this.ac_source_no_cand.bind(this);
    this.opt = { possible_years: [], on_change: () => {} };
  }
  setup(all_graph_data, opt) {
    // console.log("nav setup");
    this.all_graph_data = all_graph_data;
    this.opt = opt;
    let source = all_graph_data.nodes.filter(x => x.name.length);
    // console.log("source", source[10]);
    this.fz = FuzzySearch({
      source,
      keys: { name: "name" },
      output_map: "item"
      // thresh_include: 2
      // thresh_relative_to_best: 0.7
    });
    this.cache_details = {};
  }
  render(state) {
    // console.log("render nav_el");
    let { origin, dest } = state.query;

    let gear_button = `<div class="gear_button"><strong>⚙️</strong><span>Advanced</span></div>`;
    // Create year input
    let year_input = `<div class="year"><label for="year_input"><span>Year:</span><select name="year" id="year_input">
    ${state.possible_years.map(y => {
      let selected = y == state.year ? `selected` : ``;
      return `<option value="${y}" ${selected}>${y}</option>`;
    })}
    </select></label></div>`;

    // Create search input
    let search_inputs = `<div class="origins"><label for="query_input_origin"><span>From:</span><input id="query_input_origin" name="q_origin" type="text" placeholder="Search PACs (funders)" /></label></div>`;
    /// XXXX hackily putting the gear_button here
    search_inputs += `<div class="dests"><label for="query_input_dest"><span>To:</span><input id="query_input_dest" name="q_dest" type="text" placeholder="Search candidates and PACs (recipients)" /></label></div>`;

    // Create official linkage include_official_checkbox
    let include_official_checkbox = `<label for="include_official">Find official links:<input type="checkbox" ${
      state.include_official_linkages ? "checked" : ""
    } name="include_official" id="include_official" /></label>`;

    let depth_input = `<label>Search depth: <input type="number" pattern="[0-9]*" id="depth_input" value="${
      state.depth
    }" step="1" min="1" max="6"> links away</label>`;

    let per = origin.length || dest.length ? ` each` : ``;
    // let per = ``;
    let limit_input = `<label>Limit to top:<input type="number" pattern="[0-9]*" id="limit_input" value="${
      state.limit
    }" step="10" min="10" max="1000">links${per}</label>`;

    // for now, later put these in advanced maybe?
    // let attempts_input = "";
    // let depth_input = "";
    let attempts_input = `<label><span>Path Search Attempts:</span><input type="number" pattern="[0-9]*" id="attempts_input" value="${
      state.attempts
    }" step="1" min="2" max="10"></label>`;

    let paused_checkbox = `<label for="paused"><span>Paused:</span><input type="checkbox" ${
      state.is_paused ? "checked" : ""
    } name="paused" id="paused" /></label>`;

    // ok, our actual logic
    let descrip = `Showing top ${state.limit} funding links (of ${format_big(
      state.total_link_count
    )})`;
    descrip = origin.length ? `Showing top funding links from:` : descrip;
    descrip = dest.length ? `Showing top funding links to:` : descrip;
    descrip =
      origin.length && dest.length
        ? `Showing top funding links between:`
        : descrip;

    let advanced_descrip = ``;
    // let advanced_descrip = `<span>${
    //   state.displayed_node_count
    // } PACs and candidates with ${
    //   state.displayed_link_count
    // } funding connections shown<span>`;
    // default advanced
    let advanced = `${paused_checkbox}${limit_input} ${include_official_checkbox} ${advanced_descrip}`;
    // advanced if there's one query-type
    advanced =
      origin.length || dest.length
        ? `${paused_checkbox}${limit_input} ${depth_input} ${include_official_checkbox} ${advanced_descrip}`
        : advanced;
    // advanced if there are both to and from queries, i.e. path search mode
    advanced =
      origin.length && dest.length
        ? // ? `<label><button id="search_more">Search for more links</button></label> ${include_official_checkbox} ${advanced_descrip}`
          `${paused_checkbox}${attempts_input} ${include_official_checkbox} ${advanced_descrip}`
        : advanced;

    advanced += "<div id='show_info'>Show info</div>";
    // `<div class='message'></div>`;
    let message =
      !state.displayed_link_count &&
      !state.displayed_node_count &&
      !state.loading
        ? `<div class="message">⚠️ Sorry, nothing found for this query.</div>`
        : ``;
    // console.log(state.displayed_link_count, state.displayed_node_count);

    // descrip = `<div>⚙️</div><span>${descrip}</span>`;
    // descrip = `<span>${descrip}</span>`;
    let html = `<div id="logowrap"><svg id="logo"></svg></div>${search_inputs}${year_input}${gear_button}`;
    // let html = `<svg id="logo"></svg>${year_input}${search_inputs}`;

    // html += `<div class='block gear'>⚙️</div>`;
    html += `<div class='block long'>${descrip}</div>`;
    // html += `<div class='block'></div>`;
    html += `<div class='origins'>${state.query.origin
      .map(make_q_item)
      .join("")}</div>`;
    html += `<div class='dests'>${state.query.dest
      .map(make_q_item)
      .join("")}</div>`;
    html += `<div class='advanced'>${advanced}</div>`;
    html += `<div class='details'></div>`;
    html += message;

    //just to be used by the details dealy
    this.state = state;
    // actually write it to the element
    this.root_el.html(html);
    // update the class which controls if the advanced settings are visible
    this.root_el.classed("show_advanced", state.show_advanced);
    // setup our events
    this.postRender();
  }
  calculate(d) {
    // console.log("calculatin");
    let reducer = (accumulator, currentValue) =>
      accumulator + (currentValue.sum || 0);

    // they're the source
    let source_links = this.all_graph_data.links.filter(obj => {
      return obj["source_id"] == d["id"] && !obj.official;
    });
    let source_sum = source_links.reduce(reducer, 0);
    // they're the target
    let target_links = this.all_graph_data.links.filter(obj => {
      return obj["target_id"] == d["id"] && !obj.official;
    });
    let target_sum = target_links.reduce(reducer, 0);

    return {
      source_sum,
      target_sum,
      source_count: source_links.length,
      target_count: target_links.length
    };
    // console.log(source_links.length, target_links.length);
  }
  get_deets(d) {
    // let id = type
    // is d a string
    if (d.length) {
      let hit = this.cache_details[d];
      if (hit) {
        return hit;
      }
      d = this.all_graph_data.nodes.find(x => x.id == d);
    }
    // now d is an object
    let hit = this.cache_details[d.id];
    if (hit) {
      return hit;
    }
    let html = get_details(d, this.calculate(d), this.state);
    this.cache_details[d.id] = html;
    return html;
    // let html = get_details(d, this.calculate(d));
  }
  // when there's a hover.
  details(d) {
    if (!d) {
      this.root_el.selectAll(".details").html(``);
      return;
    }
    let html = this.get_deets(d);
    // let html = get_details(d, this.calculate(d));
    this.root_el.selectAll(".details").html(html);
    this.postDetails(d);
  }
  postDetails(d) {
    if (!d) {
      return;
    }
    // xx hacky, must do this first
    // this.root_el.select("#edit_buttons").html("get_add_buttons(d, state)");
    this.root_el.select("#edit_buttons").html(get_add_buttons(d, this.state));
    this.root_el
      .selectAll("button.add_to_query_origin")
      .on("click", (d, i, nodes) => {
        let el = d3.select(nodes[i]);
        let [id, name] = [el.attr("data-id"), el.attr("data-name")];
        this.opt.on_change(
          "search_add_origin",
          this.all_graph_data.nodes.find(x => x.id === id)
        );
      });
    this.root_el
      .selectAll("button.add_to_query_dest")
      .on("click", (d, i, nodes) => {
        let el = d3.select(nodes[i]);
        let [id, name] = [el.attr("data-id"), el.attr("data-name")];
        this.opt.on_change(
          "search_add_dest",
          this.all_graph_data.nodes.find(x => x.id === id)
        );
      });
  }
  // the function we supply to the autocomplete - literal
  // takes a term, calls suggestion() with the suggestions, we use fz
  ac_source(term, suggest) {
    term = term.toUpperCase();
    let res = this.fz.search(`name:${term}`);
    suggest(res);
  }
  ac_source_no_cand(term, suggest) {
    term = term.toUpperCase();
    let res = this.fz.search(`name:${term}`);
    // console.log(res);
    // filter out the candidates, pacs only, i.e. Committees
    res = res.filter(x => x.id.startsWith("C"));
    suggest(res);
  }
  postRender() {
    this.setupAC();
    setup_logo(this.root_el.selectAll("#logo"));
    this.root_el.selectAll("#logo, #show_info").on("click", () => {
      // XXX reaching outside
      d3.select("#ui")
        .classed("hide_info", !d3.select("#ui").classed("hide_info"))
        .classed("show_more", true);
    });
    this.root_el.selectAll(".gear_button").on("click", () => {
      this.opt.on_change(`toggle_advanced`);
    });
    // same thing twice, xxx so lazy
    this.root_el.selectAll(".dests span.q_item").on("click", (d, i, nodes) => {
      // console.log(d3.event.target);
      if (d3.event.target.innerHTML == `X`) {
        let id_to_remove = d3
          .select(d3.event.target.parentNode)
          .attr("data-id");
        // console.log("lets get rid of", id_to_remove);
        this.opt.on_change(`search_remove_dest`, id_to_remove);
      } else {
        let id = d3.select(d3.event.target).attr("data-id");
        this.details(id);
      }
    }); // basically same as above...
    this.root_el
      .selectAll(".origins span.q_item")
      .on("click", (d, i, nodes) => {
        // console.log(d3.event.target);
        if (d3.event.target.innerHTML == `X`) {
          let id_to_remove = d3
            .select(d3.event.target.parentNode)
            .attr("data-id");
          // console.log("lets get rid of", id_to_remove);
          this.opt.on_change(`search_remove_origin`, id_to_remove);
        } else {
          let id = d3.select(d3.event.target).attr("data-id");
          this.details(id);
        }
      });
    this.root_el.select("#year_input").on("change", () => {
      this.opt.on_change("year", d3.event.target.value);
    });
    this.root_el.select("#limit_input").on("change", () => {
      this.opt.on_change("limit", d3.event.target.value);
    });
    this.root_el.select("#depth_input").on("change", () => {
      this.opt.on_change("depth", d3.event.target.value);
    });
    // this.root_el.select("#search_more").on("click", () => {
    //   this.opt.on_change("attempts", true);
    // });
    this.root_el.select("#attempts_input").on("change", () => {
      this.opt.on_change("attempts", d3.event.target.value);
    });
    this.root_el.select("#include_official").on("change", () => {
      let c = d3.select(d3.event.target).property("checked");
      this.opt.on_change("include_official", c);
    });
    this.root_el.select("#paused").on("change", () => {
      let c = d3.select(d3.event.target).property("checked");
      this.opt.on_change("pause", c);
    });
    // this.root_el.selectAll(".q_item").on("mouseenter", () => {
    //   let id = d3.select(d3.event.target).attr("data-id");
    //   this.details(id);
    // });
    // this.root_el.selectAll(".q_item").on("mouseleave", () => {
    //   this.details();
    // });
  }
  setupAC() {
    let renderItem = (item, search) => {
      search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
      var re = new RegExp("(" + search.split(" ").join("|") + ")", "gi");
      let k = item.id[0];
      let kind =
        k == "C"
          ? "<span class='kind'>pac</span>"
          : `<span class='cand kind'>cand<em class="${k}">${k}</em></span>`;
      return `<div class="autocomplete-suggestion" data-name="${
        item.name
      }" data-id="${item.id}" data-val="${search}">
      ${kind}
      ${item.name.replace(re, "<b>$1</b>")}
      </div>`;
    };
    let query_input_origin = document.getElementById("query_input_origin");
    let ac = new autoComplete({
      selector: query_input_origin,
      minChars: 3,
      delay: 150, // default
      cache: false, //  XXX this was messing it up since I added fz
      source: this.ac_source_no_cand,
      onSelect: (e, term, item) => {
        let name = item.getAttribute("data-name");
        document.getElementById("query_input_origin").value = name;
        let id = item.getAttribute("data-id");
        this.opt.on_change("search_add_origin", { id, name });
      },
      renderItem
    });
    let query_input_dest = document.getElementById("query_input_dest");

    let ac_dest = new autoComplete({
      selector: query_input_dest,
      minChars: 3,
      delay: 150, // default
      cache: false, //  XXX this was messing it up since I added fz
      source: this.ac_source,
      onSelect: (e, term, item) => {
        let name = item.getAttribute("data-name");
        document.getElementById("query_input_origin").value = name;
        let id = item.getAttribute("data-id");
        this.opt.on_change("search_add_dest", { id, name });
      },
      renderItem
    });
  }
}

let make_q_item = d => {
  let k = d.id.startsWith("C") ? "pac" : `cand`;
  let k_content = d.id.startsWith("C")
    ? "pac"
    : `cand<em class="${d.id[0]}">${d.id[0]}</em>`;

  // and make get img url return the bank
  // console.log(d);
  let error_src = get_img_url(); //colors.bust_img_url
  // "https://zischwartz.github.io/us-images/congress/112x137/S000033.png";
  let img = `<img src='${get_img_url(d)}' onerror="this.src='${error_src}'" />`;
  //
  let circle = d.id.startsWith("C")
    ? `<span class="circle_bg">${img}</span>`
    : `<span class="circle_bg">${img}</span>`;
  let name = d.name ? d.name : d.id;
  return `<span class="q_item ${k}" data-id="${d.id}">
          <span class="remove" title="Remove from search">X</span>${circle}<span class='${k} kind'>${k_content}</span><span class="name">${name}</span></span>`;
};

let flag_url = require("./flag_small.png");

function setup_logo(sel) {
  sel.style("cursor", "pointer");
  sel
    .append("defs")
    .append("pattern")
    .attr("id", "flag_pat")
    // .attr("patternUnits", "userSpaceOnUse")
    .attr("width", 1)
    .attr("height", 1)
    // .attr("y", -2)
    // .attr("x", 33)
    .append("image")
    .attr("xlink:href", flag_url)
    .attr("width", 100)
    // .attr("height", 53)
    .attr("height", 53);
  // console.log(sel);
  let g = sel.append("g").attr("transform", "translate(4, 27)");
  g.append("text")
    .text("PAC")
    .attr("fill", "rgb(200,200,200)");
  // .attr("font-size", "16px");
  g.append("text")
    .classed("track", true)
    .text("TRACK.US")
    // .text("█track.us")
    .attr("fill", "url(#flag_pat)")
    .attr("x", 34)
    .attr("y", 0)
    // .attr("y", 2)
    .attr("stroke", "rgba(255,250,255, 0.5)")
    .attr("stroke-width", 0.5)
    .attr("font-weight", "400")
    .attr("letter-spacing", "1.5px");
  // .attr("font-size", "23px");

  sel.on("mouseover", () => {
    sel
      .select("text.track")
      .transition()
      .attr("x", 32)
      .attr("stroke-width", 1)

      .attr("letter-spacing", 0.5)
      .attr("font-size", "19px");
  });
  sel.on("mouseout", () => {
    sel
      .select("text.track")
      .transition()
      .attr("x", 34)
      .attr("stroke-width", 0.5)
      .attr("letter-spacing", 1.5)
      .attr("font-size", "16px");
  });
  // .attr("fill", "white");
  // g.selectAll("text"); //.attr("font-family", "sans-serif");
  g.selectAll("text").attr("font-size", "16px");
}

function get_details(d, stats, state) {
  // console.log(stats);
  let name_or_id = d.name || d.id;
  let fec_url = `http://docquery.fec.gov/cgi-bin/fecimg/?${d.id}`;
  let fec_link = `<a href="${fec_url}" target="_blank" >FEC Link</a>`;
  // let goog_url = `http://google.com/search?q=dianne+feinstein`
  let goog_url = `http://google.com/search?q=${name_or_id
    .split(" ")
    .join("+")}`;
  let goog_link = `<a href="${goog_url}" target="_blank" >Google Search</a>`;
  let add_buttons = `<span id='edit_buttons'></span>`;
  // let add_buttons = get_add_buttons(d, state);
  // add_buttons+=     d.id.startsWith("C") ? `` : "'To'"

  let m_disb = !d.disb
    ? ``
    : `<br/><span>Reported Disbursements: <em>$${format_big(
        parseFloat(d.disb) || 0
      )}</em></span> <span>Receipts: <em>$${format_big(
        parseFloat(d.recs) || 0
      )}</em></span>`;
  // let m_recs = !d.recs
  //   ? ``
  //   : ``;
  let stats_string = `<br/><span><em>${
    stats.source_count
  }</em> outgoing net contributions totaling <em>$${format_big(
    stats.source_sum
  )}</em> </span>
  <br/><span><em>${
    stats.target_count
  }</em> incoming net contributions totaling <em>$${format_big(
    stats.target_sum
  )}</em> </span>`;
  let descrip = d.id.startsWith("C")
    ? ``
    : d.id.startsWith("P")
      ? "Presidential Candidate"
      : d.id.startsWith("H")
        ? `House Candidate from ${d.id.slice(2, 4)}`
        : `Senate Candidate from ${d.id.slice(2, 4)}`;
  descrip +=
    d.party == "REP"
      ? `<span class="party rep">Republican</span>`
      : d.party == "DEM"
        ? `<span class="party dem">Democrat</span>`
        : ``;
  descrip = descrip ? `<br/><span>${descrip}</span>` : ``;
  descrip = d.id.startsWith("C") ? `` : descrip;
  // format_big
  let html = `<div><h3>${name_or_id}</h3>${fec_link} ${goog_link} ${add_buttons}${descrip}${stats_string}${m_disb}`;
  return html;
}
// just a helper for above
function get_add_buttons(d, state) {
  d = typeof d === "string" ? { id: d } : d;
  let extant_o = state.query.origin.find(x => x.id == d.id) ? `disabled` : ``;
  let extant_d = state.query.dest.find(x => x.id == d.id) ? `disabled` : ``;
  let add_buttons = !d.id.startsWith("C")
    ? ``
    : `<button ${extant_o} class="add_to_query_origin" data-id="${
        d.id
      }" data-name="${d.name}">Add to 'From' Search</button>`;

  add_buttons += `<button ${extant_d} class="add_to_query_dest" data-id="${
    d.id
  }" data-name="${d.name}">Add to 'To' Search</button>`;

  return add_buttons;
}
