let d3 = require("d3");

// let { legendSize } = require("d3Legend");
import colors from "./colors";

import { wrap } from "./util";

var dollarFormat = function(d) {
  return "$" + d3.format(",.2s")(d);
};

// https://bost.ocks.org/mike/chart/
export default function Legend() {
  // Setup
  // put api settable things here
  let width = 200;
  // let height = 50;
  // range ? yeah
  let range = [{ sum: 0 }, { sum: 50 }];
  // mrr not really the domain
  let domain = [0, 80085];

  let title = ["Net Contributions"];

  // for hovering
  let indicator = [];
  let update_indicator = () => {};
  // let indicator = [4800850 / 2];

  // the main function, we return
  // intended to be used on a single g as the selection, selection.each just for convenience
  function legend(selection) {
    // background
    selection
      .select("rect")
      .attr("x", -width * 0.05)
      .attr("y", -width * 0.2)
      .attr("fill", "rgba(15, 15, 15, 0.8)")
      .attr("width", width * 1.13)
      .attr("height", range[1]["sum"] * 4);

    var yScale = d3.scaleLinear();

    var xScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, width]);

    let xAxisScale = d3
      .scaleLinear()
      .domain(domain)
      .range([0, width]);

    var areaGenerator = d3
      .area()
      .x(function(d, i, z) {
        //		 return i * 100;
        return xScale(i);
      })
      .y0(function(d) {
        return yScale(d[0]);
      })
      .y1(function(d) {
        return yScale(d[1]);
      });

    var stack = d3
      .stack()
      .keys(["sum"])
      .offset(d3.stackOffsetWiggle);
    var stackedSeries = stack(range);
    //d3.select('g.axis')
    var axis = d3.axisBottom().scale(xAxisScale);
    //axis.ticks(3)
    // this is convoluted but works
    let step = (domain[1] - domain[0]) / 5;
    axis.tickValues(d3.range(domain[0], domain[1] + 1, step));
    axis.tickSize(30);
    axis.tickFormat(dollarFormat);
    selection.select("g.axis").call(axis);
    selection.select("g.axis .domain").attr("opacity", 0);
    // selection.select("g.axis").style("color", "white");
    selection.selectAll("g.axis text").attr("fill", "white");
    selection.selectAll("g.axis line").attr("stroke", "white");

    // selection.select("g.leg").attr("transform", "translate(20, -50)");
    selection
      .select("g.leg")
      .selectAll("path")
      .data(stackedSeries)
      .enter()
      .append("path")
      // .style("fill", "#FBB65B")
      .style("fill", colors.transaction_link)
      .attr("d", areaGenerator);

    update_indicator = () => {
      let ind = selection.selectAll("line.indicator").data(indicator);
      ind
        .exit()
        .transition()
        .attr("y1", 20)
        .remove();
      let ind_enter = ind
        .enter()
        .append("line")
        .classed("indicator", true);
      ind_enter
        .attr("x1", xAxisScale)
        .attr("x2", xAxisScale)
        .transition()
        .attr("y1", 20);
      ind = ind.merge(ind_enter);
      // console.log("update_indicator called", indicator);

      ind
        .transition()
        .attr("x1", xAxisScale)
        .attr("x2", xAxisScale)
        .attr("y1", -10)
        .attr("y2", 30)
        .attr("stroke", "white")
        .attr("stroke-width", 4);
      // .style("fill", colors.transaction_link)
      // .attr("d", areaGenerator);
    };
    update_indicator();

    selection
      .selectAll("text.title")
      .data(title)
      .enter()
      .append("text")
      .attr("class", "title")
      .text(d => d)
      .attr("text-anchor", "middle")
      .attr("y", -20)
      .attr("x", width / 2)
      .attr("font-size", "13px")
      .attr("fill", "rgb(200,200,200)");
    // selection.append("g");
  } // end main

  // Getter/setters, external api
  // *************************************************
  legend.indicator = function(value) {
    // console.log("indicator called with");
    // console.log(value);
    if (!arguments.length) return indicator;
    indicator = value;
    typeof update_indicator == "function" ? update_indicator() : "";
    return legend;
  };

  legend.width = function(value) {
    if (!arguments.length) return width;
    width = value;
    return legend;
  };
  legend.domain = function(value) {
    if (!arguments.length) return domain;
    domain = value;
    return legend;
  };

  legend.range = function(value) {
    if (!arguments.length) return range;
    // transform it into objects because stacked expects that
    range = value.map(sum => {
      return { sum };
    });
    // console.log(range);
    return legend;
  };
  return legend;
}
