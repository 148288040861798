// if (process.env.NODE_ENV != "production") {
//   window.gtag = (a, b) => {
//     // console.log("fake sending to GA:");
//     // console.log(a, b);
//   };
// }

let d3 = require("d3");

let feedback_form = `<input placeholder="What do you think?" /><button>Send</button>`;

d3.select("body")
  .append("div")
  .attr("id", "feedback_button")
  .html(`<span>FEEDBACK</span><div>${feedback_form}</div>`);

d3.select("body #feedback_button span").on("click", () => {
  // console.log("show feedback");
  let el = d3.select("#feedback_button");
  if (!el.classed("shown")) {
    el.classed("shown", true);
  }
  //   d3.select("#ui").classed("show_more", true);
});

d3.select("#feedback_button div button").on("click", () => {
  let val = d3.select("#feedback_button div input").property("value");
  // console.log(val);
  gtag("event", val, {
    event_category: `feedback`
    // event_label: ''
  });
  document.querySelector("#feedback_button").className = "";
  document.querySelector("#feedback_button span").innerHTML =
    "<strong>😀 Sent! Thanks!<strong>";
  let reset_f = () => {
    document.querySelector("#feedback_button span").innerHTML = "FEEDBACK";
  };

  setTimeout(reset_f, 5000);
  // d3.event.preventDefault();
  // console.log(d3.event);
  // console.log(document.selectElementbyI)
  // let c = d3.select("#feedback_button").attr("class");
  // console.log(c);
  // d3.select("#feedback_button").classed("shown", false);
  // c = d3.select("#feedback_button").attr("class");
  // console.log(c);

  // d3.select("#feedback_button").attr("class", "");
});
