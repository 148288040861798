let d3 = require("d3");

import colors from "./colors";

import {
  wrap,
  MakeGradientDef,
  make_unique_by,
  node_subset_from_links,
  capitalizeWords,
  Debouncer,
  get_img_url
} from "./util";

import Legend from "./legend";
// general update pattern for force
// https://bl.ocks.org/mbostock/1095795
let debouncer = new Debouncer();

// https://bost.ocks.org/mike/chart/
export default function pacGraph() {
  // Setup
  // put api settable things here
  let width = 400;
  let height = 500;
  let orient = true;
  let paused = false;
  let graph_data = { nodes: [], links: [] };
  let hover = () => {};
  let node_click = () => {};

  // gets set by paused
  let needs_sim_run = true;
  let color_scale = d3.scaleOrdinal(d3.schemeCategory10);
  // declare this outside of scope, so we can use it to update when the data changes, tsk tsk
  let runUpdate;
  let resetZoom;
  // the main function, we return
  // intended to be used on a single g as the selection, selection.each just for convenience
  function pacgraph(selection) {
    // use this to bootstrap things we don't want to rerun as we update it
    // a g for all the links
    selection.append("g").attr("id", "links_g");
    // and one for the nodes
    selection.append("g").attr("id", "nodes_g");

    // the legend
    let initial_legend_offset = 10;

    let legend_g = selection.append("g").attr("id", "legend");
    legend_g
      .style("transform-origin", "0px 45px")
      .attr("transform", `translate(${initial_legend_offset}, ${height - 50})`)
      .append("rect");
    // bootsrap our component
    legend_g.append("g").classed("axis", true);
    legend_g.append("g").classed("leg", true);
    // now zoom stuff
    let zoomed = () => {
      // console.log("ZOOMED");
      // console.log(d3.event.transform);
      let t = d3.event.transform;
      selection.selectAll("#nodes_g, #links_g").attr("transform", t);

      let left_mult = 5;
      let left_offset = initial_legend_offset;
      // initial_legend_offset + left_mult - Math.max(1, t.k) * left_mult;

      // and hide or show the legend when we zoom
      selection
        .selectAll("#legend")
        .attr(
          "transform",
          `translate(${left_offset}, ${height - 50}) scale(${t.k})`
        );
    };
    var zoom = d3
      .zoom()
      .scaleExtent([0.1, 3])
      .on("zoom", zoomed);

    // call it on the svg itself, not the main_g we apply the transform to, or drag will mess up
    d3.select(selection.node().parentNode).call(zoom);
    // our reset for when there's new data, zoom back to center on update_data
    resetZoom = () => {
      d3.select(selection.node().parentNode)
        .transition()
        .duration(750)
        .call(zoom.transform, d3.zoomIdentity);

      // .call(zoom.transform, d3.zoomIdentity);
    };

    // console.log(selection.node().parentNode);
    d3.select(selection.node().parentNode)
      .select("#back")
      .on("mousedown", () => {
        node_click();
        selection.selectAll("#nodes_g circle").style("stroke-width", "0px");
      });

    // and now our updater, defined above
    runUpdate = () => {
      // reset the zoom
      // zoomed();
      selection.each(function() {
        update.bind(this)();
      });
    };

    runUpdate();
    // selection.each(function() {
    //   // console.log("nodes", graph_data.nodes.length);
    // }); // end main selection.each
  } // end of the function we return

  // our main update function that gets rerun when we change the data, and initially
  function update() {
    // console.log("update@graph🐩");
    // select the main g
    let main_g = d3.select(this);
    // setup our scale for link widths / money
    let sum_scale = d3.scaleLinear().range([1, 25]);
    let smallest = d3.min(graph_data.links, x => x.sum);
    let sum_domain = !graph_data.links.length
      ? [0, 0]
      : [smallest, d3.max(graph_data.links, x => x.sum)];
    sum_scale.domain(sum_domain);

    let end_marker_scale = d3
      .scaleQuantize()
      .domain(sum_domain)
      .range([0, 1, 2, 3]);

    // console.log("⚠️", end_marker_scale(80));

    // redo this, what mom and genna want
    // a continual line, labeled with marks up , that way you a) avoid the 0 problem b) can scale / zoom it
    //  basically just an area chart
    //  and just do official as a separate line
    // TODO move this outside as helper, and add a modification to the first element for official
    // with dad, the search result thing needs to be bigger
    // Jane says, show the actual amount ON the scale, on_hover! nice

    let legend = new Legend().domain(sum_domain).range(sum_scale.range());
    let legend_g = main_g.select("#legend");
    legend_g.call(legend);

    // official ? todo

    // set up the simulation, don't add links or nodes yet
    let simulation = d3
      .forceSimulation()
      .force("charge", d3.forceManyBody())
      .force("col", d3.forceCollide(30)) // keep them from occluding
      .force("center", d3.forceCenter(width / 2, height / 2));

    // simulation.force("charge").distanceMin(50); // ??
    simulation.force("charge").distanceMax(450);

    if (paused) {
      simulation.nodes(graph_data.nodes).stop();
    } else {
      simulation.nodes(graph_data.nodes).on("tick", () => {
        // this likely won't happen anymore
        if (paused) {
          return;
        }
        renderTick();
      });
    }
    // this was way below, but works here
    // do normal animation, could do stop instead as above
    // simulation.nodes(graph_data.nodes).on("tick", renderTick);

    // load the links in to the simulation, must be after
    // console.log("init d3.forceLink with our data", graph_data);
    simulation.force(
      "link",
      d3
        .forceLink(graph_data.links)
        .id(d => d.id)
        .distance(250)
      // .distance(d => 100 - sum_scale(d.sum) * 10)
    );

    // Apply (my modified, verbose) general update pattern to the nodes
    //
    // select + associate data
    let node = main_g
      .select("#nodes_g")
      .selectAll("g")
      .data(graph_data.nodes, function(d) {
        if (!d) {
          return;
        }
        return d.id;
      });
    //exit
    let node_exit = node.exit();

    node_exit
      .transition()
      .duration(400)
      .remove();

    node_exit
      .select("circle.node")
      .transition()
      .duration(400)
      .attr("r", 0)
      .remove();

    node_exit.select("image").remove();
    node_exit.select("text").remove();

    // enter
    let node_enter = node.enter().append("g");
    node_enter.append("circle").attr("class", "node");
    node_enter.style("cursor", "default");

    // the bg for the name
    node_enter.append("rect").attr("class", "name_bg");

    // this makes it easier to do it conditionally and deal with the error case of no image
    node_enter.each(function(d) {
      // console.log(d);
      let c_node = d3.select(this);

      c_node
        .append("image")
        .attr("xlink:href", get_img_url)

        .attr("x", -15)
        .attr("y", -15)
        .attr("width", 30)
        .attr("height", 30)
        .on("error", (e, i, nodes) => {
          d3.select(nodes[0]).attr("xlink:href", get_img_url());
        });

      if (!d.id.startsWith("C")) {
        // party labels
        c_node
          .append("circle")
          .attr("class", "party_bg")
          .style(
            "fill",
            // "red"
            d =>
              d.party == "REP"
                ? colors.repub
                : d.party == "DEM"
                  ? colors.dem
                  : "gray"
          )
          .attr("r", 6)
          .attr("cy", 16);

        c_node
          .append("text")
          .attr("class", "party")
          .attr("text-anchor", "middle")
          .style("font-size", "10px")
          .style("fill", "white")
          // .attr("x", -5)
          .attr("y", 20);
      } // end dealing with
    }); // end our node_enter.each
    // don't forget the text, add it after, so it's on top
    node_enter.append("text").attr("class", "name");

    // use this to select subsets when i make this G instead of circle
    // enter + update
    node = node_enter.merge(node);

    node.classed("node", true);
    node.classed("node_pres", d => {
      return d.id.startsWith("P");
    });
    node.classed("node_sen", d => {
      return d.id.startsWith("S");
    });
    node.classed("node_pac", d => {
      return d.id.startsWith("C");
    });
    node.classed("node_hou", d => {
      return d.id.startsWith("H");
    });
    node.classed("active_query", d => {
      return d["active_query"];
    });

    node
      .select("text.party")
      // .attr("color", "red")
      .text(get_party_text);
    node
      .select("circle.node")
      // .style("fill", function(d, i, nodes) {
      //   // return d3.color(color_scale(d.id[0])).darker(4);
      //   let c = d3.color(color_scale(d.id[0]));
      //   return d.active_query ? c : c.darker(2);
      // })
      .call(function(node) {
        // How big should the circle be
        node.transition().attr("r", 25);
      });

    node.each((d, i, nodes) => {
      if (d["active_query"]) {
        d3.select(nodes[i]).raise();
      }
    });

    // node.select("text.icon").text(get_icon_from_d);
    // .style("font-size", "30px")
    // .attr("x", -15)
    // .attr("y", 20);
    // Ok now the text

    // how far below the circle
    let labelOffsetSize = 30;
    // our text nodes which we call wrap on and then change the rect to be bb
    let labels = node
      .select("text.name")
      .text(get_text_from_d)
      .style("font-size", d => {
        return d["active_query"] ? "16px" : "11px";
      })
      // .style("font-size", "11px")
      .attr("text-anchor", "middle")
      .attr("dy", "0em")
      .attr("y", labelOffsetSize)
      // .attr("fill", "rgb(200, 200, 200)")
      // .attr("stroke-width", 0.2)
      .call(wrap, 150);

    // http://bl.ocks.org/andreaskoller/7674031
    // changed by me
    labels.each(function(d, i) {
      graph_data["nodes"][i]["bb"] = this.getBBox();
    });
    let paddingLeftRight = 10;
    let paddingTopBottom = 10;
    node
      .select("rect.name_bg")
      .attr("x", function(d) {
        // return -d.bb.width / 2;
        return -d.bb.width / 2 - paddingLeftRight / 2;
        // return d.x - d.bb.width / 2 - paddingLeftRight / 2;
      })
      .attr("y", function(d) {
        // return -d.bb.height / 2;
        // return 0;
        return d.bb.y - paddingTopBottom / 2;
        // return paddingTopBottom / 2 + labelOffsetSize / 2;
        // return (-d.bb.height + paddingTopBottom) / 2;
      })
      .attr("width", function(d) {
        return d.bb.width + paddingLeftRight;
      })
      .attr("height", function(d) {
        return d.bb.height + paddingTopBottom;
      });

    // Now setup all our node user interactions
    let mouseovered = (d, i, nodes) => {
      // console.log(d, i, nodes[i]);
      let active = d3.select(nodes[i]);
      active.raise();
    };

    node.on("mouseover", mouseovered); //.on("mouseout", mouseouted);
    node.on("mousedown", (d, i, nodes) => {
      // reset all the others
      node.selectAll("text").style("fill", "rgb(200, 200, 200)");
      node.selectAll("circle.node").style("stroke-width", "0px");
      // highlight this one
      // not active as in query, uhh need new word
      let active = d3.select(nodes[i]);
      active.selectAll("text").style("fill", "white");
      active.selectAll("circle.node").style("stroke", "white");
      active
        .selectAll("circle.node")
        .transition()
        .style("stroke-width", "8px");
      active.raise();
      node_click(d);
    });
    // drag behavior
    // https://bl.ocks.org/mbostock/ad70335eeef6d167bc36fd3c04378048
    node.call(
      d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended)
    );
    function dragstarted() {
      if (!d3.event.active & !paused) {
        simulation.alphaTarget(0.3).restart();
      }
      d3.event.subject.fx = d3.event.subject.x;
      d3.event.subject.fy = d3.event.subject.y;
    }
    function dragged() {
      d3.event.subject.fx = d3.event.x;
      d3.event.subject.fy = d3.event.y;
      if (paused) {
        d3.event.subject.x = d3.event.x;
        d3.event.subject.y = d3.event.y;
      }
    }
    function dragended() {
      if (!d3.event.active) {
        simulation.alphaTarget(0);
      }
      d3.event.subject.fx = null;
      d3.event.subject.fy = null;
    }
    // end drag behavior

    // Apply the general update pattern to the links
    // console.log("binding links to data", graph_data.links.length);
    let link = main_g
      .select("#links_g")
      .selectAll("line")
      .data(graph_data.links, function(d) {
        return `${d.source_id}-${d.target_id}`;
      });

    //exit
    link
      .exit()
      .transition()
      .attr("stroke-opacity", 0)
      .remove();
    //enter
    let link_enter = link.enter().append("line");
    // enter+ update
    link = link_enter.merge(link);
    link.attr("class", d => {
      return `size_${end_marker_scale(d.sum) || 0}`;
    });
    link.classed("money_link", true);

    link.classed("best_path", d => {
      return d["best_path"];
    });
    link.classed("official_link", d => {
      return d["official"];
    });
    link
      .call(function(link) {
        link.transition().attr("stroke-opacity", 1);
      })
      .attr("marker-end", d => {
        if (d.best_path) {
          return `url(#marker_path_active_query_${end_marker_scale(d.sum) ||
            0})`;
        }
        if (d.official) {
          return `url(#marker_path_official)`;
        }
        return `url(#marker_path_${end_marker_scale(d.sum) || 0})`;
      })
      // .attr("marker-end", "url(#end)")
      // .attr("marker-units", "userSpaceOnUse")
      .attr("stroke-width", d => sum_scale(d.sum));

    link.on("mouseover", d => {
      legend.indicator([d.sum || 0]);
      debouncer.cancel("legend_hov");
    });
    link.on("mouseout", () => {
      debouncer.set("legend_hov", 1500, () => {
        legend.indicator([]);
      });
    });
    link.each((d, i, nodes) => {
      if (d["best_path"]) {
        // console.log("raise");
        d3.select(link[i]).raise();
      }
    });
    if (paused) {
      if (needs_sim_run) {
        for (var i = 0; i < 150; ++i) {
          simulation.tick();
        }
      }

      requestAnimationFrame(() => {
        // console.log("req calls render tick");
        renderTick();
      });
    } // end if paused
    // XXX previously, this was where I loaded up the sim, but it's more convenient to do it above

    // could try bounding it? max-distance does this tho + zoom eh
    function renderTick() {
      if (!paused && orient) {
        // Push sources up and targets down https://bl.ocks.org/mbostock/1138500
        var k = 2 * simulation.alpha();

        link.each(function(d) {
          // (d.source.y -= k), (d.target.y += k);
          // d.source.y -= k * sum_scale(d.sum);
          d.target.y += k * sum_scale(d.sum) * 0.6;
        });
      }

      link
        .attr("x1", function(d) {
          return d.source.x;
        })
        .attr("y1", function(d) {
          return d.source.y;
        })
        .attr("x2", function(d) {
          return d.target.x;
        })
        .attr("y2", function(d) {
          return d.target.y;
        });

      node.attr("transform", function(d) {
        return "translate(" + d.x + "," + d.y + ")";
      });
      if (paused) {
        // console.log("req");
        requestAnimationFrame(() => {
          // console.log("req calls render tick 2");
          renderTick();
        });
        // requestAnimationFrame(renderTick);
      }
    } // end renderTick
  } // end update

  // Getter/setters, external api
  pacgraph.graph_data = function(value, needs_zoom_reset = false) {
    // console.log("graph_data called with");
    // console.log(value);
    if (!arguments.length) return graph_data;
    graph_data = value;
    typeof runUpdate == "function" ? runUpdate() : "";
    if (needs_zoom_reset && typeof resetZoom == "function") {
      resetZoom();
    }

    return pacgraph;
  };

  pacgraph.orient = function(value) {
    if (!arguments.length) return orient;
    orient = value;
    return pacgraph;
  };
  pacgraph.width = function(value) {
    if (!arguments.length) return width;
    width = value;
    return pacgraph;
  };
  pacgraph.hover = function(value) {
    if (!arguments.length) return hover;
    hover = value;
    return pacgraph;
  };
  pacgraph.node_click = function(value) {
    if (!arguments.length) return node_click;
    node_click = value;
    return pacgraph;
  };
  pacgraph.height = function(value) {
    if (!arguments.length) return height;
    height = value;
    return pacgraph;
  };
  // basically needs sim_run_val lets us know if it's the first run or not

  pacgraph.paused = function(value, needs_sim_run_val = false) {
    if (!arguments.length) return paused;
    paused = value;
    needs_sim_run = needs_sim_run_val;
    return pacgraph;
  };
  return pacgraph;
}

// Helpers
// for displaying text of name and falling back
function get_text_from_d(d) {
  if (d.name.trim().length) {
    // return d.name;
    return capitalizeWords(d.name.toLowerCase());
  } else if (d.id.trim().length) {
    return d.id;
  } else {
    return "N/A";
  }
}

function get_party_text(d) {
  if (!d.party) {
    return `?`;
  }
  return d.party[0].toUpperCase();
}

// function get_icon_from_d(d) {
//   let letter = d.id[0];
//   if (letter == "C") {
//     return `🏦`;
//   } else {
//     return `👤`;
//   }
// }

// my attempt
// push it down if it's not a committee ie pac
// node.each(function(d) {
//   if (d["id"][0] != "C") {
//     d.y += k * 10;
//   }
// });
