let colors = {
  // transaction_link: "rgb(37, 188, 128)",
  // transaction_link: "rgb(108, 181, 45)",
  // transaction_link: "rgb(67, 181, 44)"
  // transaction_link: "rgb(19, 163, 45)",
  transaction_link: "rgb(9, 183, 46)",
  // transaction_link: "rgb(36,130,50)",
  official_link: "rgb(182, 203, 65)",
  // transaction_link_hover: "rgb(125, 223, 100)",
  transaction_link_hover: "rgb(6, 232, 70)",
  bg_color: "rgb(23,18,25)",
  active_query: "rgb(250, 40, 140)",
  // active_query: "rgb(239, 45, 86)",
  dem: "rgb(1, 8, 193)",
  repub: "rgb(193, 1, 1)",
  // ok these aren't colors
  bust_img_url: require("./bust.png"),
  bank_img_url: require("./bank.png")
  // official_link: "rgb(247, 248, 38)"
};
// console.log("colors", colors);
export default colors;
