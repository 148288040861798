import {
  make_links_unique,
  make_unique_by,
  node_subset_from_links
} from "./util";

let jKstra = require("jkstra");
// https://github.com/bbecquet/jKstra

export default class PathSearch {
  constructor(nodes, links) {
    this.links = links;
    this.nodes = nodes;
    this.graph = new jKstra.Graph();
    // this holds jkstra's nodes, because it wants the actual reference,
    // ours end up in the 'data' property of the node
    this.node_lookup = {};
    nodes.forEach((a_node, i) => {
      this.node_lookup[a_node.id] = this.graph.addVertex(a_node);
    });

    //
    // durr maybe i don't need to do this now XXX TODO, check this out later, depends on data
    // let official_links = nodes.filter(x => x.pac_cand_id).map(x => {
    //   return { source: x.id, target: x.pac_cand_id, sum: 100000 };
    // });
    // links.concat(official_links).forEach(a_link => {

    // this sets up jkstra
    links.forEach(a_link => {
      let source = this.node_lookup[a_link.source_id];
      let target = this.node_lookup[a_link.target_id];
      try {
        this.graph.addEdge(source, target, a_link.sum);
      } catch (e) {
        // console.log("fail", a_link.source, a_link.target);
      }
    });
    // console.log("done creating jKstra graph");
    // console.log(this.graph);
  }
  // User provides initial source(s) or target(s) and that's it, just branch out
  // this has nothing to do with dijkstra
  // targets = [], depth = 1, limit_to_top = 10, start_at = "source"
  traverseContrib(targets, depth, limit_to_top, start_at) {
    // console.log(targets, depth, limit_to_top, start_at);
    let go_at = start_at == "source" ? "target_id" : "source_id";
    start_at += "_id";
    // console.log(targets, depth, limit_to_top, start_at);
    // hacky & inefficient
    let result = [];
    let recurse = (origins, depth_remaining) => {
      // console.log("recurses left", depth_remaining);
      depth_remaining -= 1;
      let links = origins
        .map(id => this.links.filter(x => x[start_at] == id))
        // .map(id => this.links.filter(x => x.source == id))
        // flatten
        .reduce((acc, val) => acc.concat(val), []);
      // console.log(links[0]);

      if (limit_to_top) {
        links.sort((a, b) => b.sum - a.sum);
        links = links.slice(0, limit_to_top);
      }
      result = result.concat(links);
      if (depth_remaining) {
        // let use = start_at == "source" ? "target" : "source";
        let link_targets = links.map(d => d[go_at]);
        // let link_targets = links.map(d => d.target);
        recurse(link_targets, depth_remaining);
      }
    };
    recurse(targets, depth);
    // console.log("recurse result len, unfitlers");
    // console.log(result.length);
    let links = make_links_unique(result);
    // let links = make_unique_by(result, ["source", "target"]);
    // links (this.nodes, links);
    let nodes = node_subset_from_links(this.nodes, links);
    // console.log("n,l", nodes.length, links.length);

    return { nodes, links };
  }
  // jKstra path search one pair
  // edgeCostArgument gets passed
  // for this one too, we want to make sure we're not generating new objects for the results
  search(source_id, target_id, edgeCostArgument) {
    // or... BidirectionalDijkstra
    var dijkstra = new jKstra.algos.Dijkstra(this.graph);
    // console.log(this.node_lookup["C00428623"]);
    let [source, target] = [
      this.node_lookup[source_id],
      this.node_lookup[target_id]
    ];

    // computes the shortestPath between nodes 0 and 4,
    // using the single number stored in each as its cost
    //  passing -1 for money, or Math.random also good
    let edgeCost = function(e) {
      if (edgeCostArgument == -1) {
        return -e.data;
      } else if (typeof edgeCostArgument == "function") {
        return edgeCostArgument(e);
      } else {
        // console.log(1);
        return 1;
      }
    };
    let path = [];
    // XXX I'm not sure what's going on here, i thought it was a race condition
    // but it appears it's something else, occationally one is missing, maybe it
    // somehow has to do with the official links?
    // UPDATE: this was maybe fixed now that I converted to target_id etc
    try {
      path = dijkstra.shortestPath(source, target, { edgeCost });
    } catch (e) {
      console.log("problemo", source, target);
      return { nodes: [], links: [] };
    }
    if (!path) {
      return { nodes: [], links: [] };
    }
    // kinda unnecesary, could make a lookup for this.
    let links = path.map(e =>
      this.links.find(
        x => x["source_id"] == e.from.data.id && x["target_id"] == e.to.data.id
      )
    );
    let nodes = node_subset_from_links(this.nodes, links);

    return { nodes, links };
  }
  // it'll return something like randos+2,
  search_times_and_dedupe(source_id, target_id, randos) {
    let links_obj = {};
    let nodes_obj = {};
    // get the best route
    let best = this.search(source_id, target_id);
    best.nodes.forEach(d => {
      nodes_obj[d.id] = d;
    });
    best.links.forEach(d => {
      // flagging it this way is hacky and means I have to iterate over it later, but that takes like 10ms sooo
      d["best_path"] = true;
      links_obj[`${d.source_id}-${d.target_id}`] = d;
    });
    // run it a few times with a random heuristic
    for (var i = 0; i < randos; i++) {
      let another = this.search(source_id, target_id, Math.random);
      another.nodes.forEach(d => {
        nodes_obj[d.id] = d;
      });
      another.links.forEach(d => {
        // d["best_path"] = false;
        links_obj[`${d.source_id}-${d.target_id}`] = d;
      });
    }
    // and once more by negative sum, ie it's cheaper for the search to go in directions of more money
    let negative_sum = sum => -sum;
    let by_sum = this.search(source_id, target_id, negative_sum);
    by_sum.nodes.forEach(d => {
      nodes_obj[d.id] = d;
    });
    by_sum.links.forEach(d => {
      // d["best_path"] = false;
      links_obj[`${d.source_id}-${d.target_id}`] = d;
    });
    // console.log("a");
    // todo, export the paths
    // now just get the objects, the keys were purely to make them unique and complete
    let nodes = Object.values(nodes_obj);
    let links = Object.values(links_obj);
    return { nodes, links };
  }
  // search_for_paths(sources, targets, count) {
  // can pass in all_links and nodes, ie existing theoretically
  search_for_paths(sources, targets, count, all_links = [], all_nodes = []) {
    // let all_links = [];
    // let all_nodes = [];
    for (let source_id of sources) {
      for (let target_id of targets) {
        let { links, nodes } = this.search_times_and_dedupe(
          source_id,
          target_id,
          count
        );
        all_links = all_links.concat(links);
        all_nodes = all_nodes.concat(nodes);
      }
    }
    all_links = make_links_unique(all_links, ["source_id", "target_id", "sum"]);
    // all_links = make_unique_by(all_links, ["source", "target", "sum"]);
    all_nodes = make_unique_by(all_nodes, ["id"]);
    return { nodes: all_nodes, links: all_links };
    // oh there's no limit or depth cool, i guess there's the randos
  }
}
